import React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Page';
import PostFooter from '../components/PostFooter';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const { slug } = this.props.pageContext;

    return (
      <Page
        title={post.frontmatter.title}
        subtitle={`Posted on ${post.frontmatter.date}`}
        slug={`/blog${slug}`}
      >
        <div dangerouslySetInnerHTML={{ __html: post.html }} />

        <PostFooter category={post.frontmatter.category} />
      </Page>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "dddd, Do MMMM YYYY")
        category
      }
    }
  }
`;
